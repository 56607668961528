<div class="container">
    <!-- BEGIN MEGA MENU -->
    <!-- DOC: Apply "hor-menu-light" class after the "hor-menu" class below to have a horizontal menu with white background -->
    <!-- DOC: Remove data-hover="dropdown" and data-close-others="true" attributes below to disable the dropdown opening on mouse hover -->
    <div class="hor-menu hor-menu-light">
        <ul class="nav navbar-nav">
            <!-- Menu Level 1 -->
            <ng-template ngFor let-menuItem [ngForOf]="menu.items" let-mainMenuItemIndex="index">
                <li class="menu-dropdown classic-menu-dropdown " *ngIf="showMenuItem(menuItem)" routerLinkActive="active" [ngClass]="{'start': mainMenuItemIndex == 0}">
                    <a [routerLink]="[menuItem.route]" *ngIf="!menuItem.items.length">
                        <i class="{{menuItem.icon}}"></i>
                        <span class="title">{{l(menuItem.name)}}</span>
                    </a>
                    <a href="javascript:;" class="auto" *ngIf="menuItem.items.length">
                        <i class="{{menuItem.icon}}"></i>
                        <span class="title">{{l(menuItem.name)}}</span>
                        <span class="arrow "></span>
                    </a>
                    <ul class="dropdown-menu pull-left" *ngIf="menuItem.items.length">
                        <!-- Menu Level 2 -->
                        <ng-template ngFor let-childMenuItem [ngForOf]="menuItem.items" let-mainMenuItemIndex="index">
                            <li *ngIf="showMenuItem(childMenuItem)" routerLinkActive="active" [ngClass]="{'start': childMenuItemIndex == 0}">
                                <a [routerLink]="[childMenuItem.route]" class="nav-link  " *ngIf="!childMenuItem.items.length">
                                    <i class="{{childMenuItem.icon}}"></i>
                                    <span class="title">{{l(childMenuItem.name)}}</span>
                                </a>
                                <a href="javascript:;" class="nav-link  " *ngIf="childMenuItem.items.length">
                                    <i class="{{childMenuItem.icon}}"></i>
                                    <span class="title">{{l(childMenuItem.name)}}</span>
                                    <span class="arrow "></span>
                                </a>
                                <ul class="dropdown-menu" *ngIf="childMenuItem.items.length">
                                    <!-- Menu Level 3 -->
                                    <ng-template ngFor let-childOfChildMenuItem [ngForOf]="childMenuItem.items" let-childOfChildMenuItemIndex="index">
                                        <li class="nav-item" *ngIf="showMenuItem(childOfChildMenuItem)" routerLinkActive="active" [ngClass]="{'start': childOfChildMenuItemIndex == 0}">
                                            <a [routerLink]="[childOfChildMenuItem.route]" *ngIf="!childOfChildMenuItem.items.length">
                                                <i class="{{childOfChildMenuItem.icon}}"></i>
                                                <span class="title">{{l(childOfChildMenuItem.name)}}</span>
                                            </a>
                                            <a href="javascript:;" class="auto" *ngIf="childOfChildMenuItem.items.length">
                                                <i class="{{childOfChildMenuItem.icon}}"></i>
                                                <span class="title">{{l(childOfChildMenuItem.name)}}</span>
                                                <span class="arrow "></span>
                                            </a>
                                            <ul class="dropdown-menu" *ngIf="childOfChildMenuItem.items.length">
                                                <!-- Menu Level 4 -->
                                                <ng-template ngFor let-childOfChildOfChildMenuItem [ngForOf]="childOfChildMenuItem.items" let-childOfChildMenuItemIndex="index">
                                                    <li class="nav-item" *ngIf="showMenuItem(childOfChildOfChildMenuItem)" routerLinkActive="active" [ngClass]="{'start': childOfChildMenuItemIndex == 0}">
                                                        <a [routerLink]="[childOfChildOfChildMenuItem.route]">
                                                            <i class="{{childOfChildOfChildMenuItem.icon}}"></i>
                                                            <span class="title">{{l(childOfChildOfChildMenuItem.name)}}</span>
                                                        </a>
                                                    </li>
                                                </ng-template>
                                            </ul>
                                        </li>
                                    </ng-template>
                                </ul>
                            </li>
                        </ng-template>
                    </ul>
                </li>
            </ng-template>
        </ul>
    </div>
    <!-- END MEGA MENU -->
</div>