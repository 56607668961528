<div class="page-wrapper">
    <div class="page-wrapper-row">
        <div class="page-wrapper-top">
            <div class="page-header">
                <header></header>
            </div>
        </div>
    </div>
    <!--<div class="clearfix">
    </div>-->
    <div class="page-wrapper-row full-height">
        <div class="page-wrapper-middle">
            <!-- BEGIN CONTAINER -->
            <div class="page-container">

                <!-- BEGIN CONTENT -->
                <div class="page-content-wrapper">
                    <!-- BEGIN PAGE HEAD-->
                    <div class="page-head">
                        <div class="container">
                            <!-- BEGIN PAGE TITLE -->
                            <div class="page-title">
                                <h1>{{title}}</h1>
                                <reber-breadcrumb></reber-breadcrumb>
                            </div>
                            <!-- END PAGE TITLE -->
                            <!-- BEGIN PAGE TOOLBAR -->
                            <div class="page-toolbar">
                                <!-- BEGIN THEME PANEL -->
                                <!-- END THEME PANEL -->
                            </div>
                            <!-- END PAGE TOOLBAR -->
                        </div>
                    </div>
                    <!-- END PAGE HEAD-->
                    <div class="page-content">
                        <div class="container">
                            <div class="page-content-inner">
                                <router-outlet></router-outlet>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- chat-bar.component here... -->

            </div>
        </div>
    </div>
    <div class="page-wrapper-row">
        <div class="page-wrapper-bottom">
            <div class="page-footer">
                <footer></footer>
            </div>
            <div class="scroll-to-top">
                <i class="icon-arrow-up"></i>
            </div>
        </div>
    </div>
    <chat-bar #chatBar></chat-bar>
</div>
