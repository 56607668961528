<div bsModal #mySettingsModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content">
            <form *ngIf="active" #mySettingsModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <button type="button" class="close" aria-label="Close" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">
                        <i class="icon-settings"></i> <span>{{l("title.mySettings")}}</span>
                    </h4>
                </div>

                <div class="modal-body" *ngIf="user">
                    <tabset class="tab-container tabbable-custom">
                        <tab heading="{{l('title.profile')}}">
                            <div class="form-group">
                                <custom-label displayNameKey="firstName" isRequired="true"></custom-label>
                                <input #nameInput class="form-control edited" type="text" name="Name" [(ngModel)]="user.name" required maxlength="32">
                            </div>

                            <div class="form-group">
                                <custom-label displayNameKey="lastName" isRequired="true"></custom-label>
                                <input type="text" name="Surname" class="form-control edited" [(ngModel)]="user.surname" required maxlength="32">
                            </div>

                            <div class="form-group">
                                <custom-label displayNameKey="email" isRequired="true"></custom-label>
                                <input type="email" name="EmailAddress" class="form-control edited" [(ngModel)]="user.emailAddress" required maxlength="256">
                            </div>

                            <div *ngIf="smsEnabled && !isPhoneNumberEmpty">
                                <div class="form-group">
                                    <custom-label displayNameKey="mobile"></custom-label>
                                    <div class="input-group">
                                        <input type="text" name="PhoneNumber" class="form-control edited" [(ngModel)]="user.phoneNumber" maxlength="24">
                                        <span *ngIf="!isPhoneNumberConfirmed" class="input-group-btn">
                                            <button id="btnSmsVerification" (click)="smsVerify()" class="btn default" type="button" tooltip="{{l('YourPhoneNumberIsNotVerified')}}"><i class="fa fa-warning"></i> {{l('button.verify')}}</button>
                                        </span>

                                        <span *ngIf="isPhoneNumberConfirmed" class="input-group-addon phone-number-verified" tooltip="{{l('YourPhoneNumberIsVerified')}}">
                                            <i class="fa fa-check font-green"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="!smsEnabled || isPhoneNumberEmpty">
                                <div class="form-group">
                                    <custom-label displayNameKey="mobile"></custom-label>
                                    <input type="text" name="PhoneNumber" class="form-control edited" [(ngModel)]="user.phoneNumber" maxlength="24">
                                </div>
                            </div>

                            <div class="form-group">
                                <custom-label displayNameKey="userName" isRequired="true"></custom-label>
                                <input type="text" [disabled]="!canChangeUserName" class="form-control edited" [(ngModel)]="user.userName" name="UserName" required maxlength="32">
                                <span class="help-block" *ngIf="!canChangeUserName">{{l("CanNotChangeAdminUserName")}}</span>
                            </div>

                            <div class="form-group" *ngIf="showTimezoneSelection">
                                <custom-label displayNameKey="timeZone"></custom-label>
                                <timezone-combo [(selectedTimeZone)]="user.timezone" defaultTimezoneScope="{{defaultTimezoneScope}}"></timezone-combo>
                            </div>
                        </tab>
                        <tab *ngIf="isGoogleAuthenticatorEnabled" heading="{{l('title.twoFactorLogin')}}">
                            <div>
                                <h4>{{l('GoogleAuthenticator')}}</h4>

                                <div [hidden]="!isGoogleAuthenticatorEnabled">
                                    <div>
                                        {{l("ScanQrCodeWithYourMobileApp")}}
                                    </div>
                                    <div class="text-center">
                                        <img [src]="user.qrCodeSetupImageUrl" />
                                    </div>
                                    <small>
                                        {{l("GoogleAuthenticatorReferrerLink")}}: <a href="https://support.google.com/accounts/answer/1066447" target="_blank" rel="noopener noreferrer">Google Authenticator</a>
                                    </small>
                                </div>

                                <div [hidden]="isGoogleAuthenticatorEnabled">
                                    <button type="button" class="btn blue" (click)="updateQrCodeSetupImageUrl()">{{l("button.enable")}}</button>
                                </div>

                            </div>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn default close-button" (click)="close()" [disabled]="saving">{{l("button.cancel")}}</button>
                    <button type="submit" class="btn blue save-button" [disabled]="!mySettingsModalForm.form.valid || saving"><i class="fa fa-save"></i> <span>{{l("button.save")}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
<smsVerificationModal #smsVerificationModal (modalSave)="changePhoneNumberToVerified()"></smsVerificationModal>