<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal"
     (onShown)="shown()"
     aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header">
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">
                    {{options.title}}
                </h4>
            </div>

            <div class="modal-body">

                <form novalidate (ngSubmit)="refreshTable()" autocomplete="off">
                    <div *ngIf="options.isFilterEnabled" class="input-group margin-bottom-10">
                        <input autoFocus [(ngModel)]="filterText" name="filterText" class="form-control" placeholder="{{l('label.searchWithThreeDot')}}" type="text">
                        <span class="input-group-btn">
                            <button class="btn default" type="submit"><i class="icon-magnifier"></i></button>
                        </span>
                    </div>

                    <!--<Primeng-Datatable-Start>-->
                    <div class="primeng-datatable-container" [busyIf]="primengDatatableHelper.isLoading">
                        <p-dataTable #dataTable
                                     (onLazyLoad)="getRecordsIfNeeds($event)"
                                     [value]="primengDatatableHelper.records"
                                     rows="{{primengDatatableHelper.defaultRecordsCountPerPage}}"
                                     [paginator]="false"
                                     [lazy]="true"
                                     emptyMessage="{{l('note.noEntry')}}"
                                     [responsive]="primengDatatableHelper.isResponsive">
                            <p-column field=""  
                                      header="{{l('label.select')}}"
                                      [sortable]="false"  
                                      [style]="{'width':'18%'}"
                                      styleClass="text-center" >
                                <ng-template let-record="rowData" pTemplate="body">
                                    <button class="btn btn-default btn-xs"
                                            (click)="selectItem(record)"
                                            title="{{l('label.select')}}">
                                        <i class="fa fa-chevron-circle-right"></i>
                                    </button>
                                </ng-template>
                            </p-column>
                            <p-column field="name" header="{{l('Name')}}" [sortable]="false"></p-column>
                        </p-dataTable>
                        <div class="primeng-paging-container">
                            <p-paginator rows="{{primengDatatableHelper.defaultRecordsCountPerPage}}"
                                         #paginator
                                         (onPageChange)="getRecordsIfNeeds($event)"
                                         [totalRecords]="primengDatatableHelper.totalRecordsCount"
                                         [rowsPerPageOptions]="primengDatatableHelper.predefinedRecordsCountPerPage">
                            </p-paginator>
                            <span class="total-records-count">
                                {{l('label.totalRecordsCount', primengDatatableHelper.totalRecordsCount)}}
                            </span>
                        </div>
                    </div>
                    <!--<Primeng-Datatable-End>-->
                </form>

            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="close()">{{l("button.cancel")}}</button>
            </div>

        </div>
    </div>
</div>