<div bsModal #changePasswordModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
	<div class="modal-dialog">
		<div class="modal-content">
			<form *ngIf="active" #changePasswordModalForm="ngForm" (ngSubmit)="save()">
				<div class="modal-header">
					<button type="button" class="close" aria-label="Close" (click)="close()">
                      <span aria-hidden="true">&times;</span>
                    </button>
					<h4 class="modal-title">
						<span>{{l("title.changePassword")}}</span>
					</h4>
				</div>
				
				<div class="modal-body">
				    <div class="form-group">
                        <custom-label displayNameKey="currentPassword"></custom-label>
				        <input #currentPasswordInput type="password" name="CurrentPassword" class="form-control" [(ngModel)]="currentPassword" required>
				    </div>
				    <div class="form-group">
				        <custom-label displayNameKey="newPassword"></custom-label>
				        <input type="password" name="NewPassword" class="form-control" [(ngModel)]="password" #NewPassword="ngModel" validateEqual="NewPasswordRepeat"
				               reverse="true" [minlength]="passwordComplexitySetting.minLength" [maxlength]="passwordComplexitySetting.maxLength"
				               [useNumbers]="passwordComplexitySetting.useNumbers" [useUpperCaseLetters]="passwordComplexitySetting.useUpperCaseLetters"
				               [useLowerCaseLetters]="passwordComplexitySetting.useLowerCaseLetters" [usePunctuations]="passwordComplexitySetting.usePunctuations"
				               required>
				    </div>
					<div [hidden]="changePasswordModalForm.form.valid || changePasswordModalForm.form.pristine">
						<ul class="help-block text-danger" *ngIf="NewPassword.errors">
							<li [hidden]="!NewPassword.errors.minlength">{{l("PasswordComplexity_MinLength_Hint",passwordComplexitySetting.minLength)}}</li>
							<li [hidden]="!NewPassword.errors.maxlength">{{l("PasswordComplexity_MaxLength_Hint",passwordComplexitySetting.maxLength)}}</li>
							<li [hidden]="!NewPassword.errors.useUpperCaseLetters">{{l("PasswordComplexity_UseUpperCaseLetters_Hint")}}</li>
							<li [hidden]="!NewPassword.errors.useLowerCaseLetters">{{l("PasswordComplexity_UseLowerCaseLetters_Hint")}}</li>
							<li [hidden]="!NewPassword.errors.useNumbers">{{l("PasswordComplexity_UseNumbers_Hint")}}</li>
							<li [hidden]="!NewPassword.errors.usePunctuations">{{l("PasswordComplexity_UsePunctuations_Hint")}}</li>
						</ul>
					</div>
				    <div class="form-group">
                        <custom-label displayNameKey="newPasswordRepeat"></custom-label>
				        <input type="password" name="NewPasswordRepeat" class="form-control" #NewPasswordRepeat="ngModel" [ngModel]="passwordConfirm" validateEqual="NewPassword"
				               reverse="false" [maxlength]="passwordComplexitySetting.maxLength" required>
				    </div>
				    <div [hidden]="changePasswordModalForm.form.valid || changePasswordModalForm.form.pristine">
				        <ul class="help-block text-danger" *ngIf="NewPasswordRepeat.errors">
				            <li *ngIf="NewPasswordRepeat.dirty && NewPasswordRepeat.errors.validateEqual == false">{{l("validation.passwordRepeatNotEqual")}}</li>
				        </ul>
				    </div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn default close-button" (click)="close()" [disabled]="saving">{{l("button.cancel")}}</button>
					<button type="submit" class="btn blue save-button" [disabled]="!changePasswordModalForm.form.valid || saving"><i class="fa fa-save"></i> <span>{{l("button.save")}}</span></button>
				</div>
			</form>
		</div>
	</div>
</div>
