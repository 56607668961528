<li class="media" (click)="selectChatFriend.emit($event)">
    <div class="media-status">
        <span *ngIf="friend.unreadMessageCount" class="badge bg-yellow-casablanca">{{friend.unreadMessageCount}}</span>
    </div>
    <!--<img *ngIf="friend.friendProfilePictureId" [remoteImageSrc]="getRemoteImageUrl(friend.friendProfilePictureId, friend.friendUserId, friend.friendTenantId)" class="media-object" alt="...">-->
    <friend-profile-picture [profilePictureId]="friend.friendProfilePictureId" [userId]="friend.friendUserId" [tenantId]="friend.friendTenantId"></friend-profile-picture>
    <div class="media-body">
        <h4 class="media-heading">{{friend.friendFullName}}</h4>
        <div class="media-heading-sub">{{getShownUserName(friend.friendTenancyName,friend.friendUserName)}}</div>
    </div>
    <span class="contact-status" [ngClass]="{'online':friend.isOnline, 'offline':!friend.isOnline}"></span>
</li>