<div bsModal #linkedAccountsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
     aria-hidden="true" [config]="{keyboard: false}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{l("title.linkedAccounts")}}</span>
                    <button class="btn btn-primary blue pull-right" (click)="manageLinkedAccounts()"><i class="fa fa-plus"></i> {{l("button.linkNewAccount")}}</button>
                </h4>
            </div>
            <div class="modal-body">
                <!--<Primeng-Datatable-Start>-->
                <div class="primeng-datatable-container"
                     [busyIf]="primengDatatableHelper.isLoading">
                    <p-dataTable #dataTable
                                 (onLazyLoad)="getLinkedUsers($event)"
                                 [value]="primengDatatableHelper.records"
                                 rows="{{primengDatatableHelper.defaultRecordsCountPerPage}}"
                                 [paginator]="false"
                                 [lazy]="true"
                                 emptyMessage="{{l('note.noEntry')}}"
                                 [responsive]="primengDatatableHelper.isResponsive">
                        <p-column field=""
                                  header="{{l('Actions')}}"
                                  [sortable]="false"
                                  [style]="{'width':'20%','text-align':'center'}">
                            <ng-template let-record="rowData" pTemplate="body">
                                <button class="btn btn-xs btn-primary blue" (click)="switchToUser(record)">
                                    <i class="icon-login"></i> {{l('button.logIn')}}
                                </button>
                            </ng-template>
                        </p-column>
                        <p-column field="userName" header="{{l('label.userName')}}" [sortable]="true" styleClass="width-percent-60">
                            <ng-template let-record="rowData" pTemplate="body">
                                {{getShownLinkedUserName(record)}}
                            </ng-template>
                        </p-column>
                        <p-column field="unlink"
                                  header="{{l('label.delete')}}"
                                  [style]="{'width':'20%','text-align':'center'}"
                                  [sortable]="false">
                            <ng-template let-record="rowData" pTemplate="body">
                                <button class="btn btn-xs btn-danger red" (click)="deleteLinkedUser(record)">
                                    <i class="icon-trash"></i>
                                </button>
                            </ng-template>
                        </p-column>
                    </p-dataTable>
                    <div class="primeng-paging-container">
                        <p-paginator rows="{{primengDatatableHelper.defaultRecordsCountPerPage}}"
                                     #paginator
                                     (onPageChange)="getLinkedUsers($event)"
                                     [totalRecords]="primengDatatableHelper.totalRecordsCount"
                                     [rowsPerPageOptions]="primengDatatableHelper.predefinedRecordsCountPerPage">
                        </p-paginator>
                        <span class="total-records-count">
                            {{l('label.totalRecordsCount', primengDatatableHelper.totalRecordsCount)}}
                        </span>
                    </div>
                </div>
                <!--<Primeng-Datatable-End>-->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn default pull-right" (click)="close()">{{l("button.close")}}</button>
            </div>
        </div>
    </div>
</div>
<linkAccountModal #linkAccountModal (modalSave)="getLinkedUsers()"></linkAccountModal>