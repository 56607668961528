<!-- BEGIN QUICK SIDEBAR -->
<div class="page-quick-sidebar-wrapper" data-close-on-body-click="false">
    <a class="page-quick-sidebar-pinner" (click)="reversePinned()">
        <i class="icon-pin" [ngClass]="{'pinned':pinned, 'unpinned':!pinned}"></i>
    </a>
    <a class="page-quick-sidebar-toggler">
        <i class="icon-close"></i>
    </a>
    <div class="page-quick-sidebar">
        <div class="tab-content">
            <div class="tab-pane active page-quick-sidebar-chat" [ngClass]="{'page-quick-sidebar-content-item-shown': !isSrk}" id="quick_sidebar_tab_1">
                <div *ngIf="isSrk" class="page-quick-sidebar-chat-users" data-rail-color="#ddd" data-wrapper-class="page-quick-sidebar-list">
                    <div class="row">
                        <div class="col-md-12" style="margin: 5px; margin-left: 15px; padding-right: 25px;">
                            <h3>{{l("title.chat")}}</h3>
                        </div>
                        <div class="col-md-12" style="margin-left: 15px; padding-right: 40px;">
                            <div class="input-icon form-md-line-input input-icon-sm right">
                                <i *ngIf="tenantToTenantChatAllowed || tenantToHostChatAllowed" class="fa fa-question-circle" jq-plugin="popover" data-placement="bottom" data-html="true" title="{{l('ChatUserSearch_Hint')}}"></i>
                                <input type="text" class="form-control input-sm chat-filter-input" placeholder="{{l('FilterOrAddUser')}}" [(ngModel)]="userNameFilter">
                            </div>
                        </div>
                        <div class="col-md-12" style="margin: 15px; padding-right: 40px;">
                            <button *ngIf="userNameFilter.length" (click)="search()" type="button" class="btn btn-block blue">{{l("AddFriend")}}</button>
                        </div>
                    </div>
                    <div class="panel-group accordion" id="accFriendUsers">
                        <div class="panel">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled" data-toggle="collapse" data-parent="#accFriendUsers" data-target="#accFriendUsers_content">{{l("Friends")}}</a>
                                </h4>
                            </div>
                            <div id="accFriendUsers_content" class="panel-collapse collapse in">
                                <div class="panel-body">
                                    <ul class="media-list list-items" *ngFor="let friend of getFilteredFriends(friendDtoState.Accepted, userNameFilter)">
                                        <chat-friend-list-item [friend]="friend" (selectChatFriend)="selectFriend(friend)"></chat-friend-list-item>
                                    </ul>
                                    <p *ngIf="getFilteredFriendsCount(friendDtoState.Accepted) == 0">{{l("YouDontHaveAnyFriend")}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel-group accordion" id="accBannedUsers">
                        <div class="panel">
                            <div class="panel-heading">
                                <h4 class="panel-title">
                                    <a class="accordion-toggle accordion-toggle-styled collapsed" data-toggle="collapse" data-parent="#accBannedUsers" data-target="#accBannedUsers_content"> {{l("BlockedUsers")}} </a>
                                </h4>
                            </div>
                            <div id="accBannedUsers_content" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <ul class="media-list list-items" *ngFor="let friend of getFilteredFriends(friendDtoState.Blocked, userNameFilter)">
                                        <chat-friend-list-item [friend]="friend" (selectChatFriend)="selectFriend(friend)"></chat-friend-list-item>
                                    </ul>
                                    <p *ngIf="getFilteredFriendsCount(friendDtoState.Blocked) == 0">{{l("YouDontHaveAnyBlockedFriend")}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-quick-sidebar-item">
                    <div class="page-quick-sidebar-chat-user">
                        <div class="page-quick-sidebar-nav">
                            <a [ngClass]="{'display-hidden': !isSrk}" class="page-quick-sidebar-back-to-list">
                                <i class="icon-arrow-left"></i>{{l("button.back")}}
                            </a>
                        </div>
                        <div class="selected-chat-user">
                            <div class="selected-chat-user-image">
                                <friend-profile-picture [profilePictureId]="selectedUser.friendProfilePictureId" [userId]="selectedUser.friendUserId" [tenantId]="selectedUser.friendTenantId"></friend-profile-picture>
                                <span class="contact-status" [ngClass]="{'online': selectedUser.isOnline, 'offline':!selectedUser.isOnline}"></span>
                            </div>
                            <h5 class="dont-break-out contact-user">{{getShownUserName(selectedUser)}}</h5>
                            <div class="actions">
                                <div *ngIf="isSrk" class="btn-group">
                                    <a class="btn btn-sm blue dropdown-toggle btn-circle"  data-toggle="dropdown" data-hover="dropdown" data-close-others="true" aria-expanded="false">
                                        <i class="fa fa-angle-down"></i>
                                    </a>
                                    <ul class="dropdown-menu pull-right">
                                        <li *ngIf="selectedUser.state !== friendDtoState.Blocked">
                                            <a  (click)="block(selectedUser)">
                                                <i class="fa fa-ban"></i>
                                                {{l("BlockUser")}}
                                            </a>
                                        </li>
                                        <li *ngIf="selectedUser.state === friendDtoState.Blocked">
                                            <a  (click)="unblock(selectedUser)">
                                                <i class="fa fa-check"></i>
                                                {{l("UnblockUser")}}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="page-quick-sidebar-chat-user-messages">
                            <div *ngFor="let message of selectedUser.messages">
                                <div class="post" [ngClass]="{'out':message.side === 1 && !message.isSystem, 'in':message.side !== 1 && !message.isSystem, 'sys':message.isSystem}">
                                    <img class="avatar" alt="" *ngIf="(!isSrk || !message.userProfilePictureId) && message.side === 1 && !message.isSystem" src="{{profilePicture}}" />
                                    <friend-profile-picture *ngIf="isSrk && !!message.userProfilePictureId && message.side === 1 && !message.isSystem" [cssClass]="'avatar'" [profilePictureId]="message.userProfilePictureId" [userId]="message.userId" [tenantId]="message.tenantId"></friend-profile-picture>
                                    <friend-profile-picture *ngIf="message.side !== 1 && !message.isSystem" [cssClass]="'avatar'" [profilePictureId]="selectedUser.friendProfilePictureId" [userId]="selectedUser.friendUserId" [tenantId]="selectedUser.friendTenantId"></friend-profile-picture>
                                    <div class="message">
                                        <span class="arrow"></span>
                                        <a class="name">{{getUserNameByChatSide(message.side, message)}}</a>
                                        <span class="datetime">
                                            {{getFixedMessageTime(message.creationTime)}}
                                        </span>
                                        <span class="body"> {{message.message}} </span>
                                        <span class="preRec-link" *ngIf="message.preRecId"><a (click)="navigateToPreRec(message.preRecId)">{{l('button.gotoPreRec', message.preRecId)}}</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="page-quick-sidebar-chat-user-form">
                            <form #chatMessageForm="ngForm" name="chatMessageForm" (ngSubmit)="sendMessage()">
                                <div class="col-md-12" style="margin: 5px; padding-right: 25px;" *ngIf="selectedUser.state === friendDtoState.Blocked">
                                    <button (click)="unblock(selectedUser)" type="button" class="btn btn-block btn-success">{{l("UnblockUser")}}</button>
                                </div>
                                <div *ngIf="selectedUser.state !== friendDtoState.Blocked">
                                    <div *ngIf="isSelectedPreRecInStateComplete()" class="alert alert-warning margin-top-5 margin-bottom-5">
                                        {{l("note.chatDisabledPreCheckComplete")}}
                                    </div>
                                    <div class="padding-bottom-5" *ngIf="selectedUser.preRecs && selectedUser.preRecs.length > 1">
                                        <list-combo id="PreRec" name="PreRec" [(ngModel)]="currentPreRecId" [list]="selectedUser.preRecs" (ngModelChange)="navigateToPreRec(currentPreRecId)" [emptyText]="l('note.affectedPreCheck')" required></list-combo>
                                    </div>
                                    <textarea id="ChatMessage" name="ChatMessage" type="text" class="form-control" required
                                              autosize [minHeight]="chatMessageInputDefaultAndMinHeight" [maxHeight]="600" maxlength="4096" style="resize: none"
                                              placeholder="{{l('TypeAMessageHere')}}" [(ngModel)]="chatMessage" (ngModelChange)="setChatMessageHeight($event)"
                                              [disabled]="selectedUser.state == friendDtoState.Blocked">
                                    </textarea>
                                    <div>
                                        <button type="submit" class="btn blue send-chat-message" [disabled]="isSubmitButtonDisabled()">
                                            <i class="icon-paper-plane"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END QUICK SIDEBAR -->
<commonLookupModal #userLookupModal (itemSelected)="addFriendSelected($event)"></commonLookupModal>
